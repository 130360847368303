import * as React from "react"
import MainLayout from '../../layouts/main'
import {useContext, useEffect, useState} from "react";
import GlobalContext from "../../context/GlobalContext";
import {graphql, useStaticQuery} from "gatsby";
import Breadcrumps from "../../components/Breadcrumbs";
import Partners from "../home/partners";
import ContributiFiscali from "../home/contributi-fiscali";
import Help from "../home/help";
import GalleryButton from "../../components/GalleryButton";
import HeroPage from "../../components/HeroPage";
import SectionInstallazioni from "../../components/SectionInstallazioni";
import SeoComponent from "../../layouts/partials/SeoComponent";

const items = [
    { title: 'Prodotti', href: 'prodotti' },
    { title: 'Pergole' }
]
const installazioni = [
    {
        name: 'Le nostre installazioni',
        gallery: 'installazione-pergole.jpg',
        list: [

            {
                id: 'v1',
                video: {
                    source: [
                        {
                            src: '/prodotti/pergole/video/pergola-video-1.mp4',
                            type: 'video/mp4',
                        }
                    ],
                    attributes: { preload: false, controls: false },
                },
                thumb: '/prodotti/pergole/video/pergola-video-1.jpg',
            },
            {
                id: 'v2',
                video: {
                    source: [
                        {
                            src: '/prodotti/pergole/video/pergola-video-2.mp4',
                            type: 'video/mp4',
                        }
                    ],
                    attributes: { preload: false, controls: false },
                },
                thumb: '/prodotti/pergole/video/pergola-video-2.jpg',
            },
            {
                id: 'a1',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20240606-WA0002.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20240606-WA0002s.jpg',
            },
            {
                id: 'a2',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20240606-WA0003.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20240606-WA0003s.jpg',
            },
            {
                id: '1',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0020.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0020.jpg',
            },
            {
                id: '2',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0021.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0021.jpg',
            },
            {
                id: '3',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0022.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0022.jpg',
            },
            {
                id: '4',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0023.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0023.jpg',
            },
            {
                id: '5',
                src: '/prodotti/pergole/Pergole-90/Installazione/IMG-20191024-WA0024.jpg',
                thumb: '/thumb/pergole/Pergole-90/Installazione/IMG-20191024-WA0024.jpg',
            },
            {
                id: '6',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/01.jpg',
            },
            {
                id: '7',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/02.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/02.jpg',
            },
            {
                id: '8',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/03.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/ChiusuraLateralePvc/03.jpg',
            },
            {
                id: '9',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/Pergola/pergola.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/Pergola/pergola.jpeg',
            },
            {
                id: '10',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-01.jpg',
            },
            {
                id: '11',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-02.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-02.jpg',
            },
            {
                id: '12',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-03.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-03.jpg',
            },
            {
                id: '13',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-04.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaAddossata/pergola-addossata-04.jpeg',
            },
            {
                id: '14',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaChiusureScorrevoli/pergola-con-chiusure-vetri-scorrevoli.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaChiusureScorrevoli/pergola-con-chiusure-vetri-scorrevoli.jpeg',
            },
            {
                id: '15',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaDueModuli/pergola-due-moduli-motorizzata.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaDueModuli/pergola-due-moduli-motorizzata.jpeg',
            },
            {
                id: '16',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-01.jpg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-01.jpg',
            },
            {
                id: '17',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-02.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-02.jpeg',
            },
            {
                id: '18',
                src: '/prodotti/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-03.jpeg',
                thumb: '/thumb/pergole/PergoleInclinate/installazioni/PergolaIlluminazioneLed/pergola-illuminazione-led-03.jpeg',
            },
        ]
    },
]

const Pergole = ({pageContext}) => {
    const gContext = useContext(GlobalContext);
    const allImagesQuery = graphql`
            query {
              allFile(
                filter: {
                    relativeDirectory: { regex: "/(dettaglio)/" },
                    extension: {ne: "svg"}
                }
              ) {
                edges {
                  node {
                    base
                    childImageSharp {
                      gatsbyImageData(
                        formats: [AUTO, WEBP]     
                        placeholder: NONE          
                        quality: 100
                      )
                    }
                  }
                }
              }
            }
          `
    const {
        allFile: {edges: images},
    } = useStaticQuery(allImagesQuery)
    const [products, setProducts] = useState({});
    useEffect(() => {
        setProducts({
            prodotti: [
                {
                    name: 'Pergole 90°',
                    gallery: 'placeholder-pergole90.jpg',
                    items: [
                        {
                            id: '1',
                            src: '/prodotti/pergole/Pergole-90/MedAzimut/med-azimut-01.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedAzimut/med-azimut-01.jpg',
                        },
                        {
                            id: '2',
                            src: '/prodotti/pergole/Pergole-90/MedAzimut/med-azimut-02.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedAzimut/med-azimut-02.jpg',
                        },
                        {
                            id: '3',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-01.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-01.jpg',
                        },
                        {
                            id: '4',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-02.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-02.jpg',
                        },
                        {
                            id: '5',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-03.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-03.jpg',
                        },
                        {
                            id: '6',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-04.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-04.jpg',
                        },
                        {
                            id: '7',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-05.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-05.jpg',
                        },
                        {
                            id: '8',
                            src: '/prodotti/pergole/Pergole-90/Medisolafly/medisolafly-06.jpg',
                            thumb: '/thumb/pergole/Pergole-90/Medisolafly/medisolafly-06.jpg',
                        },
                        {
                            id: '9',
                            src: '/prodotti/pergole/Pergole-90/MedOpenFly/med-open-fly-01.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedOpenFly/med-open-fly-01.jpg',
                        },
                        {
                            id: '10',
                            src: '/prodotti/pergole/Pergole-90/MedOpenFly/med-open-fly-02.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedOpenFly/med-open-fly-02.jpg',
                        },
                        {
                            id: '11',
                            src: '/prodotti/pergole/Pergole-90/MedOpenFly/med-open-fly-03.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedOpenFly/med-open-fly-03.jpg',
                        },
                        {
                            id: '12',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-01.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-01.jpg',
                        },
                        {
                            id: '13',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-02.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-02.jpg',
                        },
                        {
                            id: '14',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-03.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-03.jpg',
                        },
                        {
                            id: '15',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-04.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-04.jpg',
                        },
                        {
                            id: '16',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-05.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-05.jpg',
                        },
                        {
                            id: '17',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-06.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-06.jpg',
                        },
                        {
                            id: '18',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-07.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-07.jpg',
                        },
                        {
                            id: '19',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-08.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-08.jpg',
                        },
                    ]
                },
                {
                    name: 'Pergole 90° 2',
                    gallery: 'placeholder-pergole-03.jpg',
                    items: [
                        {
                            id: '20',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-09.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-09.jpg',
                        },
                        {
                            id: '21',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-10.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-10.jpg',
                        },
                        {
                            id: '22',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-11.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-11.jpg',
                        },
                        {
                            id: '23',
                            src: '/prodotti/pergole/Pergole-90/MedRoom/med-room-12.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedRoom/med-room-12.jpg',
                        },
                        {
                            id: '24',
                            src: '/prodotti/pergole/Pergole-90/MedZenit/med-zenit-01.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedZenit/med-zenit-01.jpg',
                        },
                        {
                            id: '25',
                            src: '/prodotti/pergole/Pergole-90/MedZenit/med-zenit-02.jpg',
                            thumb: '/thumb/pergole/Pergole-90/MedZenit/med-zenit-02.jpg',
                        },{
                            id: '26',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-01.jpg',
                        },
                        {
                            id: '27',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-02.jpg',
                        },
                        {
                            id: '28',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-03.jpg',
                        },
                        {
                            id: '29',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-04.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-04.jpg',
                        },
                        {
                            id: '30',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-05.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-05.jpg',
                        },
                        {
                            id: '31',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-06.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-06.jpg',
                        },
                        {
                            id: '32',
                            src: '/prodotti/pergole/PergoleInclinate/MedCountryElite/med-country-elite-07.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedCountryElite/med-country-elite-07.jpg',
                        },
                        {
                            id: '33',
                            src: '/prodotti/pergole/PergoleInclinate/MedJeans/med-jeans-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedJeans/med-jeans-01.jpg',
                        },
                        {
                            id: '34',
                            src: '/prodotti/pergole/PergoleInclinate/MedJeans/med-jeans-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedJeans/med-jeans-02.jpg',
                        },
                        {
                            id: '35',
                            src: '/prodotti/pergole/PergoleInclinate/MedJeans/med-jeans-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedJeans/med-jeans-03.jpg',
                        },
                        {
                            id: '36',
                            src: '/prodotti/pergole/PergoleInclinate/MedLuce/med-luce-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedLuce/med-luce-01.jpg',
                        },
                        {
                            id: '37',
                            src: '/prodotti/pergole/PergoleInclinate/MedLuce/med-luce-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedLuce/med-luce-02.jpg',
                        },
                        {
                            id: '38',
                            src: '/prodotti/pergole/PergoleInclinate/MedLuce/med-luce-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedLuce/med-luce-03.jpg',
                        },
                        {
                            id: '39',
                            src: '/prodotti/pergole/PergoleInclinate/MedLuce/med-luce-04.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedLuce/med-luce-04.jpg',
                        },
                    ]
                },
                {
                    name: 'Pergole Inclinate',
                    gallery: 'placeholder-pergoleinclinate.jpg',
                    items: [
                        {
                            id: '40',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-01.jpg',
                        },
                        {
                            id: '41',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-02.jpg',
                        },
                        {
                            id: '42',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-03.jpg',
                        },
                        {
                            id: '43',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-04.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-04.jpg',
                        },
                        {
                            id: '44',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-05.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-05.jpg',
                        },
                        {
                            id: '45',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-06.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-06.jpg',
                        },
                        {
                            id: '46',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-07.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-07.jpg',
                        },
                        {
                            id: '47',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-08.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-08.jpg',
                        },
                        {
                            id: '48',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-09.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-09.jpg',
                        },
                        {
                            id: '49',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra/med-quadra-10.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra/med-quadra-10.jpg',
                        },
                        {
                            id: '50',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra165/med-quadra-165-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra165/med-quadra-165-01.jpg',
                        },
                        {
                            id: '51',
                            src: '/prodotti/pergole/PergoleInclinate/MedQuadra165/med-quadra-165-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedQuadra165/med-quadra-165-02.jpg',
                        },
                        {
                            id: '52',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-01.jpg',
                        },
                        {
                            id: '53',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-02.jpg',
                        },
                        {
                            id: '54',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-03.jpg',
                        },
                        {
                            id: '55',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-04.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-04.jpg',
                        },
                        {
                            id: '56',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-05.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-05.jpg',
                        },
                        {
                            id: '57',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-06.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-06.jpg',
                        },
                        {
                            id: '58',
                            src: '/prodotti/pergole/PergoleInclinate/MedViva/med-viva-07.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/MedViva/med-viva-07.jpg',
                        },
                        {
                            id: '59',
                            src: '/prodotti/pergole/PergoleInclinate/ZeroPlus/zeropiu-01.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/ZeroPlus/zeropiu-01.jpg',
                        },
                        {
                            id: '60',
                            src: '/prodotti/pergole/PergoleInclinate/ZeroPlus/zeropiu-02.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/ZeroPlus/zeropiu-02.jpg',
                        },
                        {
                            id: '61',
                            src: '/prodotti/pergole/PergoleInclinate/ZeroPlus/zeropiu-03.jpg',
                            thumb: '/thumb/pergole/PergoleInclinate/ZeroPlus/zeropiu-03.jpg',
                        },
                    ]
                },
            ],
        })
    }, [gContext])
    return (
        <MainLayout pageContext={pageContext}>
            <HeroPage
                image={gContext.getImageFromName(images, 'GIBUS_ZENIT_pergola.jpg')}
                imageAlt={'GIBUS_ZENIT_pergola.jpg'}
                title={'Pergole'}
            />
            <Breadcrumps  items={items}/>

            <div className="max-w-[1400px] mx-auto py-16 px-4 sm:px-6 lg:px-8 relative w-full">
                <div className="w-full text-justify" dangerouslySetInnerHTML={{ __html: gContext.t('pergole.intro') }}></div>

                <SectionInstallazioni items={installazioni} images={images} classes={'mb-16'}/>

                <h2 className="title-section-light text-center mb-16">{gContext.t('Tutte le tipologie disponibili')}</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-8 sm:gap-y-16 mb-8">
                    {(products?.prodotti || []).map((item) => (
                        <div key={item.name} className="min-h-[300px]">
                            <GalleryButton image={gContext.getImageFromName(images, item.gallery)} imageAlt={`image`} items={item.items} />
                        </div>
                    ))}
                </div>
            </div>

            <ContributiFiscali/>
            <Help/>
            <Partners/>
        </MainLayout>
    )
}

export default Pergole

export const Head = ({pageContext}) => (
    <SeoComponent pageContext={pageContext} />
)